/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback, useEffect, useRef } from 'react';
import type { AppProps } from 'next/app';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { WKGatherer } from '@wk/wk-gatherer';

import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { Toast } from '@/components';
import { CURRENT_LANGUAGE, OFFLINE, ONLINE } from '@/utils/constants';
import PageWrapper from '@/components/PageWrapper';
import Intercom from '@/components/Scripts/Intercom';
import AcademicAdvisor from '@/components/AcademicAdvisor';
import '@/styles/reset.css';
import '@/styles/tooltip.css';
import '@/styles/calendar.css';
import '@/styles/global.scss';
import '@/styles/kaamel.custom.scss';
import cacheStorage from '@/utils/cacheStorage';
import { IS_PROD } from '@/utils/env';
import { advisorShowPaths, hideIntercomUrls, isWhiteRoute } from '@/utils/utils';
import { isAppEnv } from '@/utils/useGetDevice';
import useIsMobile from '@/utils/hooks/useIsMobile';
// import useRouterChange from '@/utils/hooks/useRouterChange';
// import { delStorage } from '@/utils/helpers';

interface NextAppProps extends Omit<AppProps, 'Component'> {
  Component: AppProps['Component'] & { getLayout?(page: React.ReactNode): React.ReactElement };
}

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

const App = ({ Component, pageProps }: NextAppProps) => {
  const { t, i18n } = useTranslation('common');
  const Layout = Component?.getLayout || ((page) => page);
  const router = useRouter();
  const url = router.asPath;
  const pathname = router.pathname;
  const isHideIntercom = useRef<boolean>(hideIntercomUrls.some((item) => url?.toLowerCase()?.includes?.(item)));
  const advisorShowRef = useRef<boolean>(isWhiteRoute(advisorShowPaths, pathname));
  const isMobile = useIsMobile();
  // useRouterChange('subscriptionDetails', () => {
  //   delStorage('sessionStorage', 'weeklyFrequency');
  //   delStorage('sessionStorage', 'courseDuration');
  // });
  useEffect(() => {
    console.log('env-->', process.env.NEXT_PUBLIC_VERCEL_ENV, process.env.VERCEL_ENV);
    // 加载eruda控制台 （ios会缓存提前页面资源， require并不会加载 换一种方式才加载eruda）
    if (!IS_PROD && window.innerWidth <= 720) {
      import('vconsole').then((vconsole) => {
        const VConsole = vconsole.default;
        // eslint-disable-next-line no-new
        new VConsole();
      });
    }
    if (isAppEnv()) {
      isHideIntercom.current = true;
      advisorShowRef.current = false;
    }
  }, []);

  useEffect(() => {
    advisorShowRef.current = isWhiteRoute(advisorShowPaths, pathname);
  }, [pathname]);

  useEffect(() => {
    // if (!router.isReady) return;
    WKGatherer({
      config: { env: (process.env.NEXT_PUBLIC_STAGE || 'develop') as 'test' | 'prod' },
      data: {
        ak: isMobile ? 'StudentCenter_mobile' : 'StudentCenter_PC',
      },
      interval: 3000,
    });
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const disablePaths = [
      '/introduce',
      '/new-introduce',
      '/auth',
      '/home/bindPhone',
      '/agreement',
      '/notice',
      '/project',
      '/app/timeTip',
      '/app/about',
      '/app/expirationList',
      '/app/courseConsumeInfo',
      '/app/courseFeatures',
      '/m/home',
      '/app/classProcess',
      '/remark',
      '/m/bookClass',
      '/m/bookClass/private',
      '/m/home/bookClass',
      '/m/home/bookClass/private',
      '/home/bookClass',
      '/home/bookClass/private',
      '/m/learningProgress/center',
      '/app/season-confirm',
      '/notification',
      '/trial-study-report',
      '/m/selfServiceLeave',
      '/selectCourseEntry',
      '/app-middle-page',
      '/m/self-service-rescheduling',
      '/r',
      '/calendar-middle-page',
    ];
    console.log(router.asPath);
    const showTip = disablePaths?.some((item) => {
      return router.asPath?.startsWith(item);
    });
    if (showTip) {
      return;
    }
    const script = document.createElement('script');
    script.src = `/kaamel.js?`;
    script.type = 'text/javascript';
    script.id = 'kaameljs';
    document.getElementsByTagName('head')[0].appendChild(script);
    // js 加载后再去判断
    script.onload = () => {
      console.log('kaameljs onload', window.kaamelObj);
      if (typeof window !== 'undefined' && window.kaamelObj) {
        // 注册kaamel服务
        window.kaamelObj.servicesEffective(['analytics', 'doubleclick', 'googletagmanager']);
        // 判断功能是否启用获取具体适配法律框架
        window.kaamelSetting.onFrameworkLoaded = (frameworkName: 'GDPR' | 'CCPA' | '') => {
          console.log('kaameljs onFrameworkLoaded', frameworkName);

          window.kaamel.parameters.horizontalBarFontFamily = '';
          window.kaamel.parameters.mainBarBtnMarginBottom = '0px';
          window.kaamel.parameters.mainBarDenyAllBtnMarginLeft = '0px';
          window.kaamel.parameters.mainBarAcceptAllBtnMarginLeft = '16px';
          window.kaamel.parameters.mainColor = '#cd292a';
        };
      }
    };

    if (typeof window !== 'undefined') {
      window.kaamelSetting = {
        googletagmanager: {
          init() {
            console.log('kaamel - googletagmanager - init');
          },
          unload() {
            console.log('kaamel - googletagmanager - unload');
          },
        },
        analytics: {
          init() {
            console.log('kaamel - analytics - init', process.env.NEXT_PUBLIC_GA_ID);
          },
          unload() {
            console.log('kaamel - analytics - unload');
          },
        },
        doubleclick: {
          init() {
            console.log('kaamel - doubleclick - init');
          },
          unload() {
            console.log('kaamel - doubleclick - unload');
          },
        },
      };
    }
  }, []);

  // useEffect(() => {
  // if (!router.isReady) return;
  // if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', function () {
  //     navigator.serviceWorker.register('/sw.js').then(
  //       function (registration) {
  //         console.log('sw registration successful with scope: ', registration.scope);
  //       },
  //       function (err) {
  //         console.log('sw registration failed: ', err);
  //       },
  //     );
  //   });
  // }
  // }, []);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-restricted-globals
      self.addEventListener('activate', (event) => {
        // @ts-ignore
        event.waitUntil(
          (async () => {
            const keys = await caches.keys();
            // eslint-disable-next-line consistent-return
            return keys.map(async (cache) => {
              return caches.delete(cache);
            });
          })(),
        );
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const locale = router?.locale || (i18n.language === 'en' ? 'en' : 'zh');
    Cookies.remove(CURRENT_LANGUAGE);
    const timer = setTimeout(() => {
      cacheStorage.setItem(CURRENT_LANGUAGE, locale);
      clearTimeout(timer);
    }, 300);
  }, [i18n]);

  const handleOnline = useCallback(() => {
    Toast.success(t('网络已连接'));
  }, [t]);

  const handleOffline = useCallback(() => {
    Toast.warning(t('网络已断开'));
  }, [t]);

  useEffect(() => {
    window.addEventListener(ONLINE, handleOnline);
    window.addEventListener(OFFLINE, handleOffline);

    return () => {
      window.removeEventListener(ONLINE, handleOnline);
      window.removeEventListener(OFFLINE, handleOffline);
    };
  }, [handleOffline, handleOnline]);

  useEffect(() => {
    const checkIntercomAndInvoke = () => {
      if (window?.Intercom) {
        // Intercom 已初始化，调用其方法
        window?.Intercom?.('show');
        return true;
      }
      return false;
    };

    if (router.isReady && router.query.openService) {
      const intervalId = setInterval(() => {
        if (checkIntercomAndInvoke()) {
          clearInterval(intervalId);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>WuKong Learning Center</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js" async />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <style>
          {`
          body {
            overscroll-behavior:none;
          }
          body, * {
            font-family: ${i18n.language === 'en' ? 'Roboto' : '"PingFang SC", "Microsoft YaHei"'} !important;
          }

          /* 上面这个有人给body加的font-size会导致我页面的用的三方库的字体被覆盖
          但怕删了会出问题，这里打个注释标记，页面里匹配这个注释来动态删除 */
          
          /* canRemoveStyleMark */
        `}
        </style>
        <script src="https://x.klarnacdn.net/kp/lib/v1/api.js" async />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            :root {
              --kaamel-cookie-title: ${i18n.language === 'en' ? '"Customize Settings"' : '"个性化设置"'}
          }
        `,
          }}
        />
      </Head>
      <PageWrapper>
        {advisorShowRef.current && <AcademicAdvisor />}
        {!isHideIntercom.current && <Intercom />}
        {Layout(<Component {...pageProps} />)}
      </PageWrapper>
    </>
  );
};

export default appWithTranslation(App);
