import { ScheduleDetailRes } from '@/api/types/curriculum';
import { CustomModalProps } from '@/components/CustomModal';

// PRIMARY_LEAVE_APPLY 正常请假申请, ADJUST_LESSON_APPLY 调课申请, LEAVE_RECORD 申请记录, URGENT_LEAVE_APPLY 加急请假
export enum LeaveTypeEnum {
  PRIMARY_LEAVE_APPLY = 'PRIMARY_LEAVE_APPLY',
  ADJUST_LESSON_APPLY = 'ADJUST_LESSON_APPLY',
  LEAVE_RECORD = 'LEAVE_RECORD',
  CANCEL_LEAVE_RECORD = 'CANCEL_LEAVE_RECORD',
  URGENT_LEAVE_APPLY = 'URGENT_LEAVE_APPLY',
  ADJUST_RECORD = 'ADJUST_RECORD',
}

// STANDARD("标准课"), TRIAL("体验课"), PUBLIC("公开课");
export enum CourseTypeEnum {
  STANDARD = 'STANDARD',
  TRIAL = 'TRIAL',
  PUBLIC = 'PUBLIC',
}

export enum ClassTypeEnum {
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
}
export enum SubjectTypeEnum {
  CHINESE = 'CHINESE',
  MATH = 'MATH',
  ENGLISH = 'ENGLISH',
}

// NORMAL("待开课"), COMPLETED("已完成"), CANCELLED("已取消"), LEAVE_APPLIED("已提交请假申请"),ABSENT("旷课");
export enum StatusEnum {
  NORMAL = 'NORMAL',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  LEAVE_APPLIED = 'LEAVE_APPLIED',
  ABSENT = 'ABSENT',
}

export enum ReviewStatus {
  NONE_SUBJECTIVE = 'NONE_SUBJECTIVE',
  NOT_REVIEW = 'NOT_REVIEW',
  REVIEWED = 'REVIEWED',
  REMIND = 'REMIND',
}

export interface LessonProps {
  subject?: keyof typeof SubjectTypeEnum; // 科目类别
  startDateTime?: string; // 开始时间
  endDateTime?: string; // 结束时间
  status: string; // 是否结束
  classType?: keyof typeof ClassTypeEnum;
  fullName?: string;
  timeOffset: number; // 开课时间秒数
  scheduleName?: string;
  wkHeadImageUrl?: string;
  contentUrl?: string;
  courseId: string;
  classId: string;
  stuScheduleId: string;
  uid: string;
  deviceType?: number;
  hasTeacher: boolean;
  courseType: keyof typeof CourseTypeEnum;
  courseScheduleId: string;
  liveUrl: string;
  homeworkCompleted: boolean;
  reviewed: ReviewStatus;
  homework: Record<string, string>;
  courseSectionId: string;
  uuid: string;
  showHomework?: boolean;
  hasCourseComment?: boolean;
  courseEnd?: boolean;
  published?: boolean;
  platform?: string;
  leveledReaders?: boolean; // 分级/分级启蒙
  hslUrl?: string;
  hasTeachingMaterials?: boolean;
  title?: string;
  subTitle?: string;
  showCourseCommentButton?: boolean; // 课程评价按钮显示隐藏判断
  showLeaveButton?: boolean; // 请假按钮显示隐藏判断
  showMonitorButton?: boolean; // 监课按钮显示隐藏判断
  showStudyReportButton?: boolean; // 学习报告按钮显示隐藏判断
  showTrailStudyReportButton?: boolean; // 体验课学习报告按钮显示隐藏判断
  courseSchedule?: ScheduleDetailRes;
  trialStudent: boolean;
  cancelButton?: boolean; // 取消约课按钮
  canAdjust?: boolean;
  canCancel?: boolean;
  canLeave?: boolean;
  showAdjustCard?: boolean;
  showInClass?: boolean;
  leaveId?: string;
  changeTeacher?: string; // 是否换老师失败('failed'), 用于直播课取消按钮
}

// 调课结果
export enum AdjustResultEnum {
  PROCESS = 'PROCESS', // 调课进行中
  SUCCESS = 'SUCCESS', // 调课成功
  FAIL = 'FAIL', // 调课失败
}

export interface LeaveProps extends Omit<CustomModalProps, 'title'> {
  type: keyof typeof LeaveTypeEnum;
  classType: string;
  originalClassType: string;
  lessonUnitTitle: string;
  lessonTitle?: string;
  lessonTeacherName?: string;
  lessonStartDateTime?: string;
  lessonEndDateTime?: string;
  courseScheduleId?: string;
  reason?: string;
  courseUnit?: string;
  urgent?: boolean;
  adjustStatus?: AdjustResultEnum;
  whenCreated?: string;
  gtmPrefix?: (string: any, ...params: any[]) => string; // 用于埋点
  dataType: 'NEW' | 'OLD';
  duration?: number;
  newStartDateTime?: string;
  cancelTime?: string;
  status?: string;
}

export interface IExpectationsGS2Teacher {
  uuid?: string;
  fullName?: string;
  gsName?: string;
  imageUrl?: string;
}

export type TrailStatus = 'NOT_TRIAL' | 'BOOKED' | 'COMPLETED' | '';
